import React from 'react'
import { Box, Typography, Card, CardContent, Divider, Grid } from '@mui/material'
import PageHeader from '../components/headers/pageHeader'
import logo from '../KaizenLogo.webp'

const websiteOptions = [
  {
    title: 'Template in a Day',
    originalPrice: '£149/mo',
    reducedPrice: '£119/mo',
    description: 'A great looking templated website. Designed for a quick and easy process.'
  },
  {
    title: 'Single Landing Page',
    originalPrice: '£399',
    reducedPrice: '£299',
    description: 'A professional single-page website to showcase your business or product'
  },
  {
    title: '4 Page Website',
    originalPrice: '£699',
    reducedPrice: '£599',
    description: 'A multi-page website including essential pages like Home, Portfolio, Services, and Contact'
  },
  {
    title: '10 Page Website',
    originalPrice: '£899',
    reducedPrice: '£799',
    description: 'A comprehensive website with up to 10 pages, usually with individual service pages which is better for SEO.'
  }
]

const recurringServices = [
  { title: 'Hosting', price: '£25/mo', description: 'Reliable web hosting to keep your site online 24/7' },
  { title: 'Image/Text Updates', price: '£75/mo', description: 'Regular updates to keep your content fresh and relevant' },
  { title: 'GMB Optimisation', price: '£75/mo', description: 'Enhance your Google My Business profile with consistent profile optimisation to keep you on top' },
  { title: 'Updates + GMB Optimisation', price: '£125/mo', description: 'Combination of website updates and GMB optimisation for better results' },
  { title: 'SEO Articles', price: 'from £250/mo', description: 'Monthly SEO-optimised articles to improve your site’s google search rankings' },
  { title: 'Reputation Management', price: '£150/mo', description: 'Let us manage your online reputation and reviews to increase online trust'},
  { title: 'Ads', price: 'Contact for Pricing', description: 'Tailored ad campaigns to drive traffic and conversions' }
]

const guarantees = [
  '2 Week Delivery',
  'Unlimited Revisions',
  'No Templates',
  '50% Up Front'
]

const Services = () => (
  <div>
    <PageHeader headerText="Services" />
    <Box display="flex" justifyContent="center" alignItems="center" sx={{ p: 3, bgcolor: 'white' }}>
      <Card sx={{ width: '100%', maxWidth: '1200px', boxShadow: 3 }}>
        <Box display="flex" flexDirection={{ xs: 'column', lg: 'row' }}>
          {/* Left side: Website Options */}
          <CardContent sx={{ flex: 1, bgcolor: '#5c677d', color: 'white' }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2, textAlign: 'center', color: '#48cae4' }}>
              Website Options
            </Typography>
            {websiteOptions.map((option, index) => (
              <Box key={index} sx={{ mb: 3 }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  {option.title}
                </Typography>
                <Typography variant="body1">
                  <span style={{ textDecoration: 'line-through', marginRight: '8px' }}>{option.originalPrice}</span>
                  {option.reducedPrice}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  {option.description}
                </Typography>
              </Box>
            ))}
          </CardContent>

          {/* Divider */}
          <Divider orientation="vertical" flexItem sx={{ bgcolor: 'white', display: { xs: 'none', lg: 'block' }, width: '2px' }} />

          {/* Right side: Recurring Services */}
          <CardContent sx={{ flex: 2, bgcolor: '#5c677d', color: 'white' }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2, textAlign: 'center', color: '#48cae4' }}>
              Recurring Services
            </Typography>
            <Grid container spacing={2}>
              {recurringServices.map((service, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    {service.title}
                  </Typography>
                  <Typography variant="body1">
                    {service.price}
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    {service.description}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Box>

        {/* Guarantees */}
        <Divider sx={{ bgcolor: 'white', height: '2px'  }} />
        <Box sx={{ p: 2, bgcolor: '#5c677d', color: 'white', display: 'flex', justifyContent: { xs: 'center', lg: 'space-between' }, alignItems: 'center', textAlign: 'center' }}>
          <Box sx={{ flex: 1 }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2, color: '#48cae4' }}>
              Our Guarantees
            </Typography>
            {guarantees.map((guarantee, index) => (
              <Typography key={index} variant="body1" sx={{ mb: 1 }}>
                {guarantee}
              </Typography>
            ))}
          </Box>

          {/* Logo Positioned to the Right */}
          <Box
            component="img"
            src={logo}
            alt="Kaizen Development Logo"
            sx={{
              width: { xs: '50%', sm: '30%', lg: '20%' }, // Adjust the size as needed
              mt: { xs: 2, lg: 0 },
              ml: { lg: 2 } // Adds space between the guarantees and the logo on large screens
            }}
          />
        </Box>
      </Card>
    </Box>
  </div>
)

export default Services
