import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import mockup from '../Preview.webp'
import './methodology.css'
import { useNavigate } from 'react-router-dom'

const Methodology = () => {
  const navigate = useNavigate()

  const navigateLink = (link) => {
    navigate(link)
    window.scrollTo(0, 0)
  }


  return (
  <Box width={1} sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }}}>
    <Box width={{ xs: 1, md: 0.5 }} order={{ xs: 2, md: 1 }} sx={{ backgroundColor: ''}}>
      <Box sx={{ padding: 5, pt: 3 }}>
        <Typography variant="h3" sx={{ fontFamily: 'copperplate', color: '#5c677d', fontWeight: 700, mx: 'auto' }}>Kaizen Methodology</Typography>
        <Typography variant="body1" sx={{ color: '#48cae4', fontSize: '18px', textTransform: 'none', fontWeight: 700, mt: 1, pr: 5 }}>
          Our philosophy is derived from the Japanese words "kai" (change) and "zen" (good), which emphasises continuous improvement and a committment to high standards.
        </Typography>
        <Typography variant="body1" sx={{ color: '#5c677d', mt: 2, fontSize: '14px', textTransform: 'none' }}>
          At Kaizen Development, we embrace the Kaizen philosophy, which emphasizes continuous improvement through small, incremental changes. Derived from the Japanese words 
          "kai" (change) and "zen" (good), Kaizen means "change for better." This approach underpins our commitment to delivering superior web development and SEO services.
        </Typography>
        <Typography variant="body1" sx={{ color: '#5c677d', mt: 2, fontSize: '14px', textTransform: 'none' }}>
          Our dedication to Kaizen is reflected in our commitment to improving your Google rankings. Consistent, bi-monthly reviews and adjustments to our SEO strategies, 
          ensure measurable and ongoing improvements to your online presence.
        </Typography>
        <Button
          size="small"
          sx={{ backgroundColor: '#48cae4', color: 'white', p: 1, width: 'auto', float: 'right', width: 'auto', mb: 1, '&:hover': {
            backgroundColor: '#5c677d', color: 'white'
          }}}
          onClick={() => navigateLink('/about')}
        >
          Find Out More!
        </Button>
      </Box>  
    </Box>
    <Box
      width={{ xs: 0.8, md: 0.5 }}
      order={{ xs: 1, md: 2 }}
      height={{ xs: 'auto', md: '400px'}}
      sx={{
        backgroundImage: `url(${mockup})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    /> 
  </Box>
)
}

export default Methodology