import React from 'react'
import { Box, Typography, Avatar, ListItem, ListItemText, Divider, Paper } from '@mui/material'
import Slider from 'react-slick'
import CommentIcon from '@mui/icons-material/Comment'
import { NextArrow, PrevArrow } from './headers/carouselArrows'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './headers/carousel.css' 

const predefinedComments = [
  { 
    name: "Dan from DS Networks",
    text: "I want to say thank you to Tyler at Kaizen Development for sorting my website out. He's reconstructed it completely and made it look professional. He's a very nice lad, communicated really well with me throughout and he's done a fantastic job! Highly recommend!",
  },
  {
    name: "Neil from Ashwell Developments",
    text: "Huge thank you to Kaizen Development for designing and creating our new website. The site looks amazing and I recommend him for your online needs."
  },
]

const Reviews = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }

  return (
  <Box width={1} sx={{ backgroundColor: '#5c677d', py: 3 }}>
    <Typography align="center" variant="h3" sx={{ fontFamily: 'copperplate', color: 'white', fontWeight: 700, mx: 'auto', pb: 4 }}>Client Testimonials</Typography>
    <Box className="review-container">
      <Slider {...settings}>
          {predefinedComments.map((comment, index) => (
            <Box key={index} className="comment-slide">
              <Box className="comment-box">
                <Typography gutterBottom variant="h5" sx={{color: 'white', textAlign: 'center', fontWeight: '700' }}>
                  {comment.name} said:
                </Typography>
                <Typography variant="body2" sx={{color: 'white', textAlign: 'center', px: 8 }}>{comment.text}</Typography>
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>
  </Box>
  )
}

export default Reviews