import React from 'react'
import PageHeader from '../components/headers/pageHeader'
import ContactForm from '../components/contact/contactForm.jsx'

const ContactPage = () => (
  <div>
    <PageHeader headerText={"Contact Us"}/>
    <ContactForm />
  </div>
)

export default ContactPage
