// src/pages/NotFoundPage.js
import React from 'react'
import { Box, Typography, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const NotFoundPage = () => {
  const navigate = useNavigate()

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '70vh',
        textAlign: 'center',
        padding: 3,
        color: '#5c677d', // Grey color for text
      }}
    >
      <Typography variant="h1" sx={{ fontFamily: 'copperplate', fontWeight: 700, mb: 2, color: '#5c677d' }}>
        404
      </Typography>
      <Typography variant="h5" sx={{ fontFamily: 'copperplate', mb: 3, color: '#5c677d' }}>
        Page Not Found
      </Typography>
      <Typography variant="body1" sx={{ fontFamily: 'copperplate', mb: 4, color: '#5c677d' }}>
        Sorry, the page you are looking for does not exist.
      </Typography>
      <Button
        size="large"
        sx={{ backgroundColor: '#48cae4', color: 'white', '&:hover': {
                  backgroundColor: '#5c677d'
                }}}
        onClick={() => navigate('/')}
      >
        Go to Homepage
      </Button>
    </Box>
  )
}

export default NotFoundPage
