import React from 'react'
import { Box, Typography } from '@mui/material'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './carousel.css'  
import background from '../../kaizenbg.webp'

const HomepageCarousel = () => {
  return (
    <Box
      width={1}
      sx={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '500px',
        width: '100%',
        //filter: 'blur(8px)',
      }}
    > 
      <Box
        className="headerTypography"
        width={{ xs: '90%', sm: '80%', md: '70%', lg: '60%' }}
        sx={{ pl: { xs: 3, sm: 8, md: 16 }, pt: { xs: 12, sm: 12, md: 12 } }}
      >
        <Typography
          variant="h2"
          sx={{
            color: 'white',
            fontWeight: 700,
            fontSize: { xs: '2rem', sm: '2rem', md: '2.5rem' },
            fontFamily: 'copperplate',
          }}
        >
          Kaizen Development
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontFamily: 'copperplate',
            color: 'white',
            fontWeight: 700,
            mt: 6,
            fontSize: { xs: '1.5rem', sm: '1.5rem', md: '2rem' },
          }}
        >
          Exceptional Web Solutions through Continuous Improvement
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontFamily: 'copperplate',
            color: 'white',
            fontWeight: 700,
            mt: 6,
            fontSize: { xs: '1.5rem', sm: '1.5rem', md: '1.5rem' },
          }}
        >
          Based in Brentwood, Essex - we build quality and custom web solutions for businesses looking to improve their online presence
        </Typography>
      </Box>
      {/*<Box className="headerTypography" width={0.8} sx={{ pl: 15, pt: 12 }}>
        <Typography variant="h2" sx={{ fontFamily: 'copperplate', color: 'white', fontWeight: 700 }}>Kaizen Development</Typography>
        <Typography variant="body1" sx={{ fontFamily: 'copperplate', color: 'white', mt: 6, fontSize: '28px' }}>Exceptional Web Solutions through Continuous Improvement</Typography>
        <Typography variant="body1" sx={{ fontFamily: 'copperplate', color: 'white', mt: 4, fontSize: '28px', display: { xs: 'none', md: 'block' } }}>
          Based in Brentwood, Essex - we build quality and custom web solutions for businesses looking to improve their online presence
        </Typography>
        </Box>*/}
    </Box>
  )
}

export default HomepageCarousel
