import React from 'react'
import { Box, Typography } from '@mui/material'
import './footer.css'
import logo from '../KaizenLogo.webp' // Assuming the logo is saved in the same directory
import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'

const Footer = () => (
  <Box className="footer-container">
    <Box className="footer-content">
      <Box className="footer-left">
        <Box className="footer-contact-item">
          <EmailIcon className="footer-icon" />
          <Typography variant="body1" className="footer-text">tyler@kaizen-web.dev</Typography>
        </Box>
        <Box className="footer-contact-item">
          <PhoneIcon className="footer-icon" />
          <Typography variant="body1" className="footer-text">+44 7552 638270</Typography>
        </Box>
      </Box>
      <img src={logo} alt="Kaizen Development Logo" className="footer-logo" />
      <Typography variant="body1" className="footer-text">
        &copy; {new Date().getFullYear()} Kaizen Development. All rights reserved.
      </Typography>
    </Box>
  </Box>
)

export default Footer
