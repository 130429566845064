import React from 'react'
import { Box, Button, TextField, Typography, Grid } from '@mui/material'
import { useForm } from 'react-hook-form'
import emailjs from 'emailjs-com'
import { useNavigate } from 'react-router-dom'

const ContactForm = () => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm()
  const navigate = useNavigate()

  const navigateLink = (link) => {
    navigate(link)
    window.scrollTo(0, 0)
  }


  const onSubmit = (data) => {
    console.log(data)
    sendEmail(data)
  }

  const sendEmail = (data) => {
    emailjs.send(
      'Kaizen ',
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      {
        to_name: 'Tyler', // Replace with your name or a placeholder
        from_name: data.Name,
        message: data.Message,
        email: data.Email,
        phone: data.Phone || '',
      },
      process.env.REACT_APP_EMAILJS_USER_ID
    )
    .then((response) => {
      alert("Your message has been recorded. We are on it!")
      reset()
    })
    .catch((error) => {
      alert("Failed to send message.")
      console.error("EmailJS Error:", error)
    })
  }

  return (
    <Box width={1} sx={{ backgroundColor: 'white', pt: 3, display: 'flex', justifyContent: 'center' }}>
      <Box className="form-container" sx={{ width: { xs: '80%', sm: '80%' }}}>
        <Typography align="center" variant="h3" sx={{ fontFamily: 'copperplate', color: '#5c677d', fontWeight: 700 }}>
          Get in Touch
        </Typography>
        <Typography align="center" variant="body1" sx={{ color: '#48cae4', fontSize: '18px', textTransform: 'none', fontWeight: 700, mt: 1 }}>
          We're here to answer any queries about our services. We aim to respond to within 2 business days.
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} sx={{ my: 3 }}>
            <Grid item xs={12} md={4} container direction="column" spacing={2} className="left-fields">
              <Grid item>
                <TextField
                  fullWidth
                  label="Name"
                  variant="outlined"
                  {...register("Name", { required: true })}
                  error={!!errors.Name}
                  helperText={errors.Name ? 'Name is required' : ''}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  label="Email Address"
                  type="email"
                  variant="outlined"
                  {...register("Email", { required: true })}
                  error={!!errors.Email}
                  helperText={errors.Email ? 'Email is required' : ''}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  label="Phone Number"
                  type="tel"
                  variant="outlined"
                  {...register("Phone")}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={8} className="right-field" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', position: 'relative' }}>
              <TextField
                fullWidth
                label="Message/ Query"
                variant="outlined"
                multiline
                rows={8}
                {...register("Message", { required: true })}
                error={!!errors.Message}
                helperText={errors.Message ? 'Message is required' : ''}
              />
              <Button
                type="submit"
                variant="contained"
                sx={{ backgroundColor: '#48cae4', color: 'white', alignSelf: 'flex-end', mt: 2, '&:hover': {
                  backgroundColor: '#5c677d'
                }}}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  )
}

export default ContactForm
