import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Homepage from './index.jsx'
import About from './pages/about.jsx'
import Portfolio from './pages/portfolio.jsx'
import Services from './pages/services.jsx'
import ContactPage from './pages/contact.jsx'
import NotFoundPage from './pages/404.jsx'

export default () => (
  <Routes>
    <Route path="/" element={<Homepage />} />
    {/*<Route path="/about" element={<About />} /> */}
    <Route path="/contact" element={<ContactPage />} />
    <Route path="/portfolio" element={<Portfolio />} />
    <Route path="/services" element={<Services />} />
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
)
