import React, { useState } from 'react'
import { AppBar, Box, Button, Toolbar, Typography, Menu, MenuItem, IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import './navbar.css'  // Import the CSS file
import logo from '../KaizenLogo.webp'
import CallIcon from '@mui/icons-material/Call'
import MenuIcon from '@mui/icons-material/Menu'

const pages = [{ name: 'Portfolio', link: 'portfolio' }, { name: 'Services', link: 'services' }, { name: 'Contact', link: 'contact'}]

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const navigate = useNavigate()

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = (link) => {
    if (link) navigate(link)
    window.scrollTo(0, 0)
    setAnchorEl(null)
  }

  return (
    <AppBar className="navbar" position="static">
      <Toolbar className="navbar">
        <Button className="navbar-logo" onClick={() => navigate('/')}>
          <img src={logo} alt="Kaizen Logo" style={{ height: '48px', maxWidth: '100%' }} />
        </Button>
        <Box className="navbar-links">
          {pages.map(({ name, link }) => (
            <Button key={name} className="navbar-button" onClick={() => navigate(`${link}`)} sx={{ padding: { sm: '1px'}, mx: {sm: '4px'}}}>
              {name}
            </Button>
          ))}
        </Box>
        <Box className="navbar-contact">
          <CallIcon sx={{ color: '#48cae4', my: 'auto', height: '32px', width: '32px' }} />
          <Typography className="contact-text" gutterBottom variant="h6" sx={{ color: '#48cae4', textAlign: 'center', fontWeight: '700', my: 'auto' }}>
            +44 7552 638270
          </Typography>
        </Box>
        <IconButton 
          edge="end" 
          className="navbar-menu-button"
          onClick={handleMenuOpen} 
        >
          <MenuIcon sx={{ color: '#48cae4' }} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => handleMenuClose(null)}
          sx={{ display: { xs: 'block', sm: 'block' }, p: 0, m: 0 }}
        >
          {pages.map(({ name, link }) => (
            <MenuItem key={name} onClick={() => handleMenuClose(link)} sx={{ color: '#48cae4'}}>
              {name}
            </MenuItem>
          ))}
        </Menu>
      </Toolbar>
    </AppBar>
  )
}

export default Navbar
