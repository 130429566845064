import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import Homepage from './index.jsx'
import Navbar from './navbar/navbar.jsx'
import Routes from './routes.jsx'
import Footer from './footer/footer.jsx'

const queryClient = new QueryClient({})

const App = () => (
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
    <>
      <Navbar />
      <div className="body">
        <Routes />
      </div>
      <Footer />
    </>
    </BrowserRouter>
  </QueryClientProvider>
)

export default App