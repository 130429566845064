import React from 'react'
import { Box, Divider, Typography } from '@mui/material'
import PageHeader from '../components/headers/pageHeader'
import screenshot from '../ashwells.webp'
import dsnetworks from '../dsnetworks.webp'
import read from '../landscaping.webp'

const Portfolio = () => (
  <div>
    <PageHeader headerText={"Portfolio"}/>
    <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', md: 'row' }, 
          height: 'auto',
        }}
      >
        <Box 
          sx={{ 
            width: { xs: '100%', md: '64%' }, 
            backgroundColor: '', 
            display: 'flex', 
            alignItems: 'left',
          }}
        >
          <img 
            src={read} 
            alt="Screenshot of a website" 
            style={{ width: '100%', height: 'auto', maxHeight: '100%' }}
          />
        </Box>
        <Box 
          sx={{ 
            width: { xs: 'auto', md: '36%' }, 
            backgroundColor: '', 
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2, color: '#5c677d', mx: 'auto', textAlign: 'center', }}>
            Read Landscaping
          </Typography>
          <Typography variant="body1" sx={{ color: '#5c677d', textAlign: 'center', mb: 4 } }>
            Fully custom coded website from scratch for a local landscaping company. A professional look matching with the company's colour scheme, containing 
            individual service pages, a contact page and a transformation gallery.
          </Typography>
        </Box>
      </Box>    
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', md: 'row' }, 
          height: 'auto',
        }}
      >
        <Box 
          sx={{ 
            width: { xs: '100%', md: '64%' }, 
            backgroundColor: '', 
            display: 'flex', 
            alignItems: 'left',
          }}
        >
          <img 
            src={dsnetworks} 
            alt="Screenshot of a website" 
            style={{ width: '100%', height: 'auto', maxHeight: '100%' }}
          />
        </Box>
        <Box 
          sx={{ 
            width: { xs: 'auto', md: '36%' }, 
            backgroundColor: '#5c677d', 
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            justifyContent: 'center'
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2, color: 'white', mx: 'auto' }}>
            DS Networks
          </Typography>
          <Typography variant="body1" sx={{ color: 'white', textAlign: 'center', mb: 4} }>
            Website transformation for a network installation company. Client had an old, self-made Wordpress website, and asked for me to 
            transform his website, changing the look, theme and content. Was limited with the free Wordpress plan but shows we are versatile.
          </Typography>
          <Typography variant="body1" sx={{ color: 'white', textAlign: 'center' } }>
            "I want to say thank you to Tyler at Kaizen Development for sorting my website out. He's reconstructed it completely and made it look professional. He's a very nice lad, communicated really well with me throughout and he's done a fantastic job! Highly recommend!",
          </Typography>
        </Box>
      </Box>
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', md: 'row' }, 
          height: 'auto',
        }}
      >
        <Box 
          sx={{ 
            width: { xs: '100%', md: '64%' }, 
            backgroundColor: '', 
            display: 'flex', 
            alignItems: 'left',
          }}
        >
          <img 
            src={screenshot} 
            alt="Screenshot of a website" 
            style={{ width: '100%', height: 'auto', maxHeight: '100%' }}
          />
        </Box>
        <Box 
          sx={{ 
            width: { xs: 'auto', md: '36%' }, 
            backgroundColor: '', 
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2, color: '#5c677d', mx: 'auto', textAlign: 'center', }}>
            Ashwell Developments 
          </Typography>
          <Typography variant="body1" sx={{ color: '#5c677d', textAlign: 'center', mb: 4 } }>
            Fully custom coded website from scratch for a house development/ renovation company. We went for a professional and clean look,
            with the main focus of the homepage being on examples of house transformations.
          </Typography>
          <Typography variant="body1" sx={{ color: '#5c677d', textAlign: 'center' } }>
            "Huge thank you to Kaizen Development for designing and creating our new website. The site looks amazing and I recommend him for your online needs."
          </Typography>
        </Box>
      </Box>    
    <Divider />
  </div>
)

export default Portfolio
