import React from 'react'
import HomepageCarousel from './components/headers/homepageCarousel'
import { Box, Grid } from '@mui/material'
import './index.css'
import HomepageSplitter from './components/splitters/homepageSplitters'
import Methodology from './components/methodology'
import Reviews from './components/reviews'
import ContactForm from './components/contact/contactForm'

const Homepage = () => (
  <>
    <HomepageCarousel />
    <Box width={1} sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }}} />
      <HomepageSplitter />
      <Methodology />
    <Box />
    <Reviews />
    <ContactForm />
  </>
)

export default Homepage
