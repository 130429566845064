import React from 'react'
import { Box, Button, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import HighQualityIcon from '@mui/icons-material/HighQuality'
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart'
import SupportIcon from '@mui/icons-material/Support'
import ShareIcon from '@mui/icons-material/Share'
import './splitters.css'
import { useNavigate } from 'react-router-dom'

const HomepageSplitter = () => {
  const navigate = useNavigate()

  const navigateLink = (link) => {
    navigate(link)
    window.scrollTo(0, 0)
  }

  return (
    <Box width={1} sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, height: 'auto' }}>
      <Box width={{ xs: 1, md: 0.5 }} sx={{ backgroundColor: '#5c677d', display: 'flex', alignItems: 'left' }}>
        <Box className="doubleParent" sx={{ padding: 5, pt: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h3" sx={{ fontFamily: 'copperplate', color: 'white', fontWeight: 700 }}>Services</Typography>
            <Button
              size="small"
              sx={{ backgroundColor: '#48cae4', color: 'white', p: 1, width: 'auto', '&:hover': {
                backgroundColor: 'white', color: '#5c677d'
              }}}
              onClick={() => navigateLink('/services')}
            >
              Our Packages
            </Button>
          </Box>
          <List sx={{ pt: 0 }}>
            <ListItem disablePadding>
              <ListItemIcon>
                <HighQualityIcon sx={{ color: '#48cae4' }} />
              </ListItemIcon>
              <ListItemText
                sx={{ color: 'white' }}
                primary="Custom coded, responsive web design"
                primaryTypographyProps={{ color: '#48cae4', fontWeight: 700, fontSize: '18px' }}
                secondary="No templates used, custom coded for YOU to maximise website performance with unlimited revisions to ensure your satisfaction. However, we can upgrade your current setup too."
                secondaryTypographyProps={{ color: 'white' }}
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemIcon>
                <MonitorHeartIcon sx={{ color: '#48cae4' }} />
              </ListItemIcon>
              <ListItemText
                sx={{ color: 'white' }}
                primary="Ongoing support and maintenance"
                primaryTypographyProps={{ color: '#48cae4', fontWeight: 700, fontSize: '18px' }}
                secondary="With our recurring plans, enjoy ongoing support, GMB profile management and SEO support after your website is live."
                secondaryTypographyProps={{ color: 'white' }}
              />
            </ListItem>
            <ListItem disablePadding sx={{ pt: 0 }}>
              <ListItemIcon>
                <SupportIcon sx={{ color: '#48cae4' }} />
              </ListItemIcon>
              <ListItemText
                sx={{ color: 'white' }}
                primary="Fully assisted setup"
                primaryTypographyProps={{ color: '#48cae4', fontWeight: 700, fontSize: '18px' }}
                secondary="From domain purchase and setup to email creation and website hosting, we’ve got you covered."
                secondaryTypographyProps={{ color: 'white' }}
              />
            </ListItem>
            <ListItem disablePadding sx={{ pt: 0 }}>
              <ListItemIcon>
                <ShareIcon sx={{ color: '#48cae4' }} />
              </ListItemIcon>
              <ListItemText
                sx={{ color: 'white' }}
                primary="Digital marketing strategies"
                primaryTypographyProps={{ color: '#48cae4', fontWeight: 700, fontSize: '18px' }}
                secondary="Leveraging our trusted marketing partners, we can deliver customised strategies for maximum digital impact."
                secondaryTypographyProps={{ color: 'white' }}
              />
            </ListItem>
          </List>
        </Box>
      </Box>
      <Box width={{ xs: 1, md: 0.5 }} sx={{ backgroundColor: 'white', display: 'flex' }}>
        <Box className="doubleParent" sx={{ padding: 5, pt: 3 }}>
          <Typography variant="h3" sx={{ fontFamily: 'copperplate', color: '#5c677d', fontWeight: 700, mx: 'auto' }}>Our Goal</Typography>
          <Typography variant="body1" sx={{ color: '#48cae4', fontSize: '18px', textTransform: 'none', fontWeight: 700, mt: 1 }}>
            At Kaizen Development, our goal is to revolutionize the online presence of your business, ensuring a steady stream of new customers find their way to you.
          </Typography>
          <Typography variant="body1" sx={{ color: '#5c677d', fontSize: '14px', textTransform: 'none', mt: 1 }}>
            We achieve this by leveraging our expertise in website creation and maintenance, comprehensive SEO optimisation, and strategic approaches designed to elevate 
            your business in Google rankings. 
          </Typography>
          <Typography variant="body1" sx={{ color: '#5c677d', fontSize: '14px', textTransform: 'none', mt: 1 }}> 
            Our commitment is to empower your business with a strong, competitive online presence that dominates search engine results 
            and drives continuous growth and success.
          </Typography>
          <Typography variant="body1" sx={{ color: '#5c677d', fontSize: '14px', textTransform: 'none', mt: 1 }}> 
            With experienced developers having over 4 years of professional experience, we specialise in providing premium services tailored to meet the unique needs of
            each client. We are dedicated to helping businesses in Brentwood and Essex enhance their online visibility and reach their full potential.
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default HomepageSplitter
