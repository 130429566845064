import React from 'react'
import { Box, Typography } from '@mui/material'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './carousel.css'  
import background from '../../kaizenbg.webp'

const PageHeader = ({ headerText }) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: '40%',
        height: '350px',
        width: '100%',
      }}
    > 
     <Box width={0.8} sx={{ pl: { xs: 4, sm: 5, md: 15 }, pt: 20 }}>
        <Typography
          variant="h2"
          sx={{
            fontFamily: 'copperplate',
            color: 'white',
            fontWeight: 700,
            fontSize: { xs: '2.5rem', sm: '2.5rem', md: '3rem' },
          }}
        >
          {headerText}
        </Typography>
      </Box>
    </Box>
  )
}

export default PageHeader
